a:link, a:visited {
  color: white;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

#button-close {
  background-image: url("../../images/baseline-close-24px.svg");
  background-size: contain;
  min-width: 50px;
  min-height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  margin: 50px;
  display: none;
  z-index: 5;
}

#button-menu {
  background-image: url("../../images/baseline-more_vert-24px.svg");
  background-size: contain;
  min-width: 50px;
  min-height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  margin: 50px;
  display: none;
}

.videowrapper {
  float: none;
  clear: both;
  width: 100%;

  padding-bottom: 56.2%;
  height: 0;

  /* Put the video in the background */
  position: absolute;
  top: 0;
  left: 0;
}
.videowrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#hud {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: none;
  pointer-events:none;

  -webkit-transition: opacity 2s ease-in-out;
  -moz-transition: opacity 2s ease-in-out;
  -o-transition: opacity 2s ease-in-out;
  transition: opacity 2s ease-in-out;
}

#preloader {
  top: 50%;
  left: 50%;
  position: absolute;
}

#smallscreen {
  top: 0;
  left: 0;
  position: absolute;
  display: none;
}

#logo {
  background-image: url("../../images/Logo512x512_Foz360.png");
  background-size: contain;
  min-width: 265px;
  min-height: 265px;
  position: absolute;
  left: 40%;
  top: -7%;
  display: none;
}

#language {
  margin-left: auto;
  margin-right: auto;
  width: 50%;

  -moz-transform: scale(0.40);
  -webkit-transform: scale(0.40);
  transform: scale(0.40);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #008CBA;
}

#credit {
  bottom: 2%;
  left: 2%;
  position: absolute;

  color:white;
}

#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  height: auto;
}

#myImg:hover {opacity: 0.7;}

.responsive {
  position: relative;
  top: 270px;
  left: 25%;
  padding: 0 15px;
  float: left;
  width: 20%;
}

#logoindex {
  display: block;
  position: absolute;
  left: 37%;
  top: 2%;
  float: left;
  width: 20.99999%;
  height: auto;
}